import React from "react"
import { Link } from "react-router-dom"
import * as F from "./footerNew.style";

// Images
import Logo from "assets/images/myCoaching/Foodiary-Logo-White.png"
import PlayStore from "assets/images/myCoaching/play-store.png"
import AppStore from "assets/images/myCoaching/app-store.png"
import Instagram from "assets/images/new/instagram.svg";
import Pinterest from "assets/images/new/pinterest.svg";
import Linkedin from "assets/images/new/linkedin.svg"
import { useSelector } from "react-redux";

function FooterNew () {
  const coach = useSelector((state) => state.coach.coach)

  return (
    <F.FooterWrap>
      <F.Container className="ct-section-inner-wrap">
        <F.FooterContent>
          <div className="top-footer">
            <F.FooterInfo>
              <Link to="/">
                <F.Logo src={Logo} alt="Footer Logo" />
              </Link>
              <div className="app-logos">
                <a href="https://www.foodiary.app/get-the-app/" className="ct-link hover-float">
                  <img id="image-32-15" className="ct-image ios" src={AppStore} alt="Ios App" />
                </a>
                <a href="https://www.foodiary.app/get-the-app/" className="ct-link hover-float">
                  <img id="image-32-15" className="ct-image google" src={PlayStore} alt="Android App" />
                </a>
              </div>
              <div>
                <p>Smarte Ernährungsplanung und individuelle Betreuung für ein gesundes Leben voller Energie und Selbstbewusstsein.</p>
              </div>
            </F.FooterInfo>
            <F.FooterLinks>
              <F.FooterContentUlSectionInner>
                <F.Title className="text-2xl color-white">Angebot</F.Title>
                <div className="links">
                  <Link className="text-base color-paragraph-alt" to="/erstgespraech">Erstgespräch</Link>
                  <Link className="text-base color-paragraph-alt" to="/coaching-pro">Coaching PRO</Link>
                  <a className="text-base color-paragraph-alt" href="https://my.foodiary.app" target="_blank">Foodiary App</a>
                  <Link className="text-base color-paragraph-alt" to="/online-kurs">Präventionskurs</Link>
                  <a className="text-base color-paragraph-alt" href="https://my.foodiary.app" target="_blank">Login Lernacademy</a>
                </div>
              </F.FooterContentUlSectionInner>
              <F.FooterContentUlSectionInner>
                <F.Title className="text-2xl color-white">About</F.Title>
                <div className="links">
                  <a className="text-base color-paragraph-alt" href="https://www.foodiary.app">Über Foodiary</a>
                  <a className="text-base color-paragraph-alt" href="http://foodiary.app/magazin/">Foodiary Magazin</a>
                  <a className="text-base color-paragraph-alt" href="https://foodiary.app/partner/coach/">Werde Coach</a>
                </div>
              </F.FooterContentUlSectionInner>
              <F.FooterContentUlSectionInner>
                <F.Title className="text-2xl color-white">Service</F.Title>
                <div className="links">
                  <a className="text-base color-paragraph-alt" href={`mailto:${coach?.email}`}>E-Mail</a>
                  <a className="text-base color-paragraph-alt" href={coach?.instagram_url || "#!"}>Instagram</a>
                  <a className="text-base color-paragraph-alt" href={coach?.whatsapp_url}>Whatsapp</a>
                  <a className="text-base color-paragraph-alt" href="https://foodiary.app/terms/datenschutz/">Datenschutz</a>
                  <a className="text-base color-paragraph-alt" href="https://foodiary.app/terms/imprint/">Impressum</a>
                  <a className="text-base color-paragraph-alt" href="https://foodiary.app/terms/agb-coaching/">AGB</a>
                </div>
              </F.FooterContentUlSectionInner>
            </F.FooterLinks>
          </div>
          <div className="bottom-footer">
            <div>
              <span className="ct-text-block text-xs pr-12">© {new Date().getFullYear()} Foodiary GmbH. All rights reserved.</span>
            </div>
            <div>
              <div>
                <a className="ct-link-text text-sm color-paragraph-alt" href="https://coach.foodiary.app">Login für Coaches</a>
              </div>
              <div>
                <a className="ct-link-text text-sm color-paragraph-alt" href="#">Mitgliedschaft kündigen</a>
              </div>
              <div className="social">
                <a className="ct-link-text text-sm color-paragraph-alt icon" href="http://instagram.com/foodiary.app">
                  <img src={Instagram} alt="instagram" width="30px" height="30px" />
                </a>
                <a className="ct-link-text text-sm color-paragraph-alt icon" href="https://www.pinterest.de/foodiaryapp/">
                  <img src={Pinterest} alt="pinterest" width="30px" height="30px" />
                </a>
                <a className="ct-link-text text-sm color-paragraph-alt icon" href="https://www.linkedin.com/company/34645628/">
                  <img src={Linkedin} alt="linkedin" width="30px" height="30px" />
                </a>
              </div>
            </div>
          </div>
        </F.FooterContent>
      </F.Container>
    </F.FooterWrap>
  )
}

export default FooterNew
