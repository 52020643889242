import React from "react";
import { useHistory } from "react-router-dom";
import { PopupButton } from "react-calendly";
import Chip from "components/General/formComponents/chip/Chip";
import * as C from "./startTogether.styled";
// Images
import StartTogetherImage from "assets/images/Page3/Foodiary-Coaching-start.svg";

const StartTogether = ({ onLeadFormModal }) => {
  const history = useHistory();

  return (
    <C.Wrap className="NutritionCoachWrap">
      <C.TextContent>
        <Chip text="ERSTGESPRÄCH" margin="0 0 30px 0" />
        <C.Title className="ct-headline foodiary-h2 color-dark text-4xl">
          Starten wir jetzt gemeinsam!
        </C.Title>
        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          Ich verspreche dir, dass wir dein Leben in den nächsten 3 Monaten
          verändern werden. Durch deine Ernährungsumstellung, welche von mir
          geführt wird, wirst du nicht nur dein körperliches Ziel erreichen,
          sondern auch zu einem ganz neuen Körpergefühl finden.
        </C.Description>
        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          Als dein Teampartner unterstütze ich dich dabei!
        </C.Description>
        <C.Action>
          <div className="mt-10">
            <button className="btn-dark" onClick={() => onLeadFormModal()}>Erstgespräch vereinbaren</button>
          </div>
        </C.Action>
      </C.TextContent>
      <C.VisualContent>
        <img src={StartTogetherImage} alt="together" />
      </C.VisualContent>
    </C.Wrap>
  );
};

export default StartTogether;
