import React from "react";
import * as C from "./HelpWithSurvey.style";
import LeadsSurveyForm from "components/LeadsSurvey/LeadsSurveyForm";

const HelpWithSurvey = () => {

  return (
    <C.SectionContainer>
      <div>
        <div className="survey-content">
          <div className="survey-headline">
            <h3>Wie darf ich dir weiterhelfen?</h3>
          </div>
          <div className="survey-description">
            <p>Als dein persönlicher Ernährungsberater helfe ich dir weiter, sodass du dein persönliches Ziel erreichst. Hierfür ist es zunächst wichtig zu wissen, welches Ziel du verfolgst und wie ich dich dabei unterstützen kannst.</p>
            <p>Verrate mir dein Ziel und deine Bedürfnisse unverbindlich über das Kontaktformular.</p>
          </div>
        </div>
        <div className="form-wrapper">
          <LeadsSurveyForm isInPagePlacement />
        </div>
      </div>
    </C.SectionContainer>
  );
};

export default HelpWithSurvey;
