import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import StickyNavbar from "components/StickyNavbar/StickyNavbar";
import FooterNew from "components/FooterNew/FooterNew";
import SectionWrapper from "components/General/sectionWrapper/SectionWrapper";

import InitialConsultationBox from "components/InitialConsultationBox/InitialConsultationBox";
import PreventionCourse from "components/PreventionCourse/PreventionCourse";
import ConsciousNutritionCourse from "components/ConsciousNutritionCourse/ConsciousNutritionCourse";
import InsuranceCompanies from "components/InsuranceCompanies/InsuranceCompanies";
import LearningMaterial from "components/LearningMaterial/LearningMaterial";
import PreventionCourseSteps from "components/PreventionCourseSteps/PreventionCourseSteps";
import VideoCourseAdvantages from "components/VideoCourseAdvantages/VideoCourseAdvantages";
import FreeCourseSteps from "components/FreeCourseSteps/FreeCourseSteps";
import MaximumReimbursement from "components/MaximumReimbursement/MaximumReimbursement";
import ReimbursementCalculation from "components/ReimbursementCalculation/ReimbursementCalculation";

// Redux
import * as CoachActions from "redux/coach/coach.actions";

// Styles
import * as G from "styles/General.Styles";
import * as C from "./OnlineKurs.styles";
import LeadSurveyModal from "modals/LeadSurveyModal/LeadSurveyModal";

import { IS_LOCAL } from "configs/utils";

const OnlineKurs = (props) => {
  const dispatch = useDispatch();
  const coach = useSelector((state) => state.coach.coach);

  const [openLeadModal, setOpenLeadModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (coach) return

    const coach_website_url = IS_LOCAL
      ? 'https://ronja-fci49818.foodiary.app'
      : `${window.location.protocol}//${window.location.host}`

    dispatch(
      CoachActions.trainerIdRequest({
        formData: { coach_website_url },
        callback: (res) => {
          dispatch(
            CoachActions.coachDataRequest({
              formData: { trainerID: res?.trainerID },
              callback: () => null,
            })
          );
        },
      })
    );
  }, [dispatch, coach]);

  return (
    <>
      <StickyNavbar openSurveyModal={() => setOpenLeadModal(!openLeadModal)} />
      
      <C.Header>
        <C.HeaderInner>
          <G.Container>
            <SectionWrapper margin="20rem 0 0" padding="0 0 35rem" mobilePadding="0 0 0px">
              <ConsciousNutritionCourse />
            </SectionWrapper>
          </G.Container>
        </C.HeaderInner>
      </C.Header>

      <G.Container>

        <SectionWrapper margin="-20rem 0 0" mobileMargin="10rem 0 0">
          <InsuranceCompanies />
        </SectionWrapper>

        <SectionWrapper margin="20rem 0 0" mobileMargin="10rem 0 0">
          <PreventionCourse />
        </SectionWrapper>

        <SectionWrapper margin="20rem 0 0" mobileMargin="10rem 0 0">
          <LearningMaterial />
        </SectionWrapper>

        <SectionWrapper margin="20rem 0 0" mobileMargin="10rem 0 0">
          <PreventionCourseSteps />
        </SectionWrapper>

        <SectionWrapper margin="20rem 0 0" mobileMargin="10rem 0 0">
          <VideoCourseAdvantages />
        </SectionWrapper>

        <SectionWrapper margin="20rem 0 0" mobileMargin="10rem 0 0">
          <FreeCourseSteps />
        </SectionWrapper>

        <SectionWrapper margin="20rem 0 0" mobileMargin="10rem 0 0">
          <MaximumReimbursement />
        </SectionWrapper>

        <SectionWrapper margin="20rem 0 0" mobileMargin="10rem 0 0">
          <ReimbursementCalculation />
        </SectionWrapper>

      </G.Container>

      <SectionWrapper margin="20rem 0 0" mobileMargin="20rem 0 0">
        <InitialConsultationBox openLeadFormModal={() => setOpenLeadModal(true)} />
      </SectionWrapper>
      <FooterNew />

      {coach && (
        <LeadSurveyModal
          isOpen={openLeadModal}
          onClose={() => setOpenLeadModal(false)}
          coachProfile={coach}
        />
      )}
    </>
  );
};

export default OnlineKurs;
