import styled from "styled-components";

export const WhyIamYourCoachContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: center;

  @media screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .image-preview-wrapper {
    background: white;
    padding: 10px;
    border-radius: 15px;
    width: 470px;
    box-shadow: ${props => props.shadow ? "0px 10px 15px -3px rgba(0,0,0,0.1)" : "none" };

    @media screen and (max-width: 600px) {
      width: 100%;
    }

    img {
      width: 100%;
      border-radius: 15px;
    }
  }

  .content-preview {
    display: flex;
    flex-direction: column;
    color: #44554C;

    @media screen and (max-width: 600px) {
      h3 {
        font-size: 20px;
      }
    }

    > * {
      margin-bottom: 20px;
    }
    
    .cta-overview {
      @media screen and (max-width: 600px) {
        display: flex;
        justify-content: center;
      }
    }
  }
`
