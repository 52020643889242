import React from "react";
import * as F from "./InitialConsultationBox.style";

const InitialConsultationBox = ({ openLeadFormModal }) => {
  return (
    <F.Wrapper>
      <F.Content className="d-flex flex-column align-items-center text-center">
        <F.Title
          id="text_block-52-15"
          className="ct-text-block color-dark text-3xl font-bold my-0"
        >
          Jetzt mit Foodiary Coach starten!
        </F.Title>
        <F.Description className="ct-text-block text-base color-paragraph font-normal mt-12 mb-12 text-center">
          Dein persönlicher Ernährungsberater sorgt für Klarheit im wirren
          Diätenwahnsinn. <br /> Er steht auf deinem Weg, zu einem gesünderen
          Leben, an deiner Seite.
        </F.Description>
        <button className="ct-link-text hover-float btn-dark" onClick={openLeadFormModal}>Erstgespräch vereinbaren</button>
      </F.Content>
    </F.Wrapper>
  );
};

export default InitialConsultationBox;
