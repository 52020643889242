import styled from "styled-components";

export const SectionContainer = styled.div`
  background: #EEEEEE;
  padding: 60px 20px;
  
  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 100px;
    max-width: 151.7rem;
    margin: 0px auto;
    align-items: center;

    @media screen and (max-width: 1000px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }

  .survey-content {
    display: flex;
    flex-direction: column;
    color: #142C1F;

    @media screen and (max-width: 600px) {
      .survey-headline h3 {
        font-size: 18px !important;
      }

      p {
        text-align: center;
      }
    }

    .survey-description {
      > * {
        margin-bottom: 20px;
      }
    }

    > * {
      margin-bottom: 30px;
    }
  }

  .form-wrapper {
    
  }
`
