import styled from "styled-components";
import { LoginSubHeading } from "pages/login/login.styles";

export const InformationHeading = styled(LoginSubHeading)`
  padding-top: 45px;
`;

export const InformationDescription = styled.div`
  font-size: 16px;
  font-family: "Rubik-Regular";
  color: #44554c;
  margin: 0 0 40px 0;
`;

export const Header = styled.header`
  background: transparent linear-gradient(90deg, #e5f5f2 0%, #edfbf6 100%);
`;

export const HeaderInner = styled.div`
  @media (max-width: 600px) {
    margin-top: -40px;
  }
`;
