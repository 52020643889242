import styled from "styled-components"

export const RelativeContainer = styled.div`
  position: relative;
`

export const NavbarContainer = styled.div`
  max-width: 151.7rem;
  width: 100%;
  margin-inline: auto;
  padding: 20px 20px;
  border-radius: 15px;
  position: fixed;
  left: calc(50% - 151.7rem / 2);
  top: 20px;
  background: ${props => props.isActiveBg ? 'white' : 'none'};
  box-shadow: ${props => props.isActiveBg ? '0px 5px 10px #142C1F14' : 'none'};
  z-index: 900;
  transition: all 100ms ease-in-out;

  .mobile-menu-links {
    display: flex;
    flex-direction: column;

    .is-active {
      color: #2ECF96;
    }
    
    a {
      color: black;
      padding: 10px 0px;
      font-size: 2rem;
    }
  }

  .mobile-menu-content {
    background: white;
    padding: 20px;

    .mobile-menu-cta {
      padding: 10px;
    }
  }

  @media (max-width: 1200px) {
    max-width: 100%;
    width: 93%;
    left: 3.5%;
  }

  .new-navbar-actions-mobile {
    display: none;
  }

  .new-navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .new-navbar-logo {
      img {
        width: 150px;
      }
    }
    
    .new-navbar-cta {
      display: flex;
      align-items: center;
      gap: 20px;

      button {
        padding: 10px 10px;
        font-size: 14px;
      }
    }
    
    .new-navbar-actions-desktop {
      display: flex;
      align-items: center;
      gap: 100px;

      @media (max-width: 1050px) {
        gap: 30px;
      }

      .new-navbar-links {
        display: flex;
        gap: 30px;
        font-size: 16px;

        a {
          color: #142C1F;
          padding: 10px 0px;
          position: relative;

          &:after {
            transition: all 300ms ease-in-out;
            background: rgb(68, 85, 76);
            position: absolute;
            content: '';
            height: 3px;
            width: 0px;
            left: calc(50% - 15px);
            bottom: 0;
            border-radius: 5px;
            opacity: 0;
          }

          &:hover {
            &:after {
              opacity: 1;
              width: 30px;
            }
          }
        }
        
        .is-active {
          &:after {
            background: #2ECF96;
            opacity: 1;
            width: 30px;
          }
        }
      }
    }
    
    .new-navbar-actions-mobile {
      align-items: center;
      gap: 10px;

      .new-navbar-login, .new-navbar-mobile-trigger {
        a {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    
    @media (max-width: 1000px) {
      .new-navbar-actions-mobile {
        display: flex;
      }
      
      .new-navbar-actions-desktop {
        display: none;
      }
    }
  }
`

export const DropdownWrapper = styled.div`

  min-width: 200px;


  ul {
    padding: 10px !important;

    li {
      padding: 0px !important;

        > * {
          padding: 0px !important;
        }

      &:hover {
        background: none !important;

        a {
          color: #2ECF96;
        }
      }
    }
  }
`

export const DropSubItem = styled.div`
  padding: 10px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 10%;
    left: 0;
    height: 80%;
    width: 2px;
    background-color: white;
    transition: background-color 0.3s;
  }

  &:hover::before {
    background-color: #2ECF96;
  }

  a {
    color: #44554C;
  }
`

export const DropSubItemOutline = styled.div`
  padding: 8px 0px;
  text-align: center;
  margin-top: 5px;
  border: 2px solid  #25a08b;
  border-radius: 10px;

  &:hover {
    background:  #25a08b;

    a {
      color: white !important;
    }
  }

  a {
    padding: 20px 0px;
    font-weight: bold;
  }
`

export const DropDivider = styled.div`
  background: lightgrey;
  height: 1px;
  margin: 10px 0px;
`

export const CollapseWrapper = styled.div`
  > * {
    > * {
      padding: 10px 0px !important;

      > * {
        padding: 0px !important;
        font-size: 2rem;
      }
    }
  }
`

export const ExpandIcon = styled.div`
  img {
    width: 15px;
  }
`

export const CollapseSubMenu = styled.div`
  display: flex;
  flex-direction: column;

  a {
    font-size: 14px !important;
  }
`
