import React from "react";
import { useSelector } from "react-redux";
import { getS3ImageUrl } from "configs/utils";

import * as C from "./TogetherWithMe.styled";

import Chip from "components/General/formComponents/chip/Chip";
// Images
import Coach from "assets/images/Page3/Foodiary-Coaching-start.svg";
import GreenTick from "assets/images/myCoaching/Icon-check.svg";
import CentralShape from "assets/images/pngs/central-shape.png";

const TogetherWithMe = () => {
  const coach = useSelector((state) => state.coach.coach);

  const imgUrl = coach?.cw_cp_picture_2
    ? getS3ImageUrl(coach?.cw_cp_picture_2)
    : Coach;

  return (
    <C.Wrap>
      <C.VisualContent>
        {coach?.cw_fm_picture_2 && (
          <C.Image maskImage={CentralShape}>
            <img src={imgUrl} alt="Foodiary-Coaching-Bild2" />
          </C.Image>
        )}
        {!coach?.cw_fm_picture_2 && (
          <img src={imgUrl} alt="Foodiary-Coaching-Bild2" />
        )}
      </C.VisualContent>
      <C.TextContent>
        <Chip text="COACHING PRO" margin="0 0 4rem 0" />
        <C.Title className="ct-headline foodiary-h2 color-dark text-4xl">
          Gemeinsam mit mir zu einem gesünderen Leben
        </C.Title>

        <p className="ct-text-block color-paragraph text-base text-left mt-6">
          Mit dem Ernährungscoaching PRO unterstütze ich dich dabei innerhalb
          der nächsten 3 Monate dein persönliches Ziel zu erreichen. Gemeinsam
          mit dir entwickle ich eine Strategie und einen Ernährungsplan, um
          deine Ernährung dauerhaft umzustellen. Als Foodiary Coach betreue ich
          dich dabei dauerhaft und stehe bei Fragen an deiner Seite.
        </p>
      </C.TextContent>
    </C.Wrap>
  );
};

export default TogetherWithMe;
