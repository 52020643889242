import React from "react";
import * as F from "./rightCoach.styled";
import * as G from "styles/General.Styles";
// Images
import WhiteTick from "assets/images/Page2/Icon-check-green-white.svg";
import GreenTick from "assets/images/myCoaching/Icon-check.svg";

const RightCoach = () => {
  return (
    <F.RightCoachContainer>
      <F.Title className="ct-headline foodiary-h2 color-dark text-3xl uppercase font-semibold">
        ICH BIN DER RICHTIGE COACH FÜR DICH, WENN DU…
      </F.Title>
      <F.TwoColums>
        <li>
          <G.PlanBoxIconBox className="WidthChanges">
            <img src={GreenTick} alt="Tick" />
          </G.PlanBoxIconBox>
          <G.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
            …Unterstützung bei deiner Lebensumstellung suchst
          </G.PlanBoxInformation>
        </li>
        <li>
          <G.PlanBoxIconBox className="WidthChanges">
            <img src={GreenTick} alt="Tick" />
          </G.PlanBoxIconBox>
          <G.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
            ...Tipps für eine vielfältige und gesunde Ernährung brauchst
          </G.PlanBoxInformation>
        </li>
        <li>
          <G.PlanBoxIconBox className="WidthChanges">
            <img src={GreenTick} alt="Tick" />
          </G.PlanBoxIconBox>
          <G.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
            ...dich im Alltag gesund und flexibel ernähren möchtest
          </G.PlanBoxInformation>
        </li>
        <li>
          <G.PlanBoxIconBox className="WidthChanges">
            <img src={GreenTick} alt="Tick" />
          </G.PlanBoxIconBox>
          <G.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
            ...bereit bist den ersten Schritt in ein neues Leben zu gehen
          </G.PlanBoxInformation>
        </li>
        <li>
          <G.PlanBoxIconBox className="WidthChanges">
            <img src={GreenTick} alt="Tick" />
          </G.PlanBoxIconBox>
          <G.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
            ...Eine Ernährungsberatung möchtest, die individuell auf dich und
            dein Leben abgestimmt ist
          </G.PlanBoxInformation>
        </li>
        <li>
          <G.PlanBoxIconBox className="WidthChanges">
            <img src={GreenTick} alt="Tick" />
          </G.PlanBoxIconBox>
          <G.PlanBoxInformation className="ct-text-block foodiary-list-item text-base color-dark text-left font-normal">
            ...ein gut durchdachtes Konzept suchst, bei dem du keine
            kurzweiligen Erfolge durch Diäten und Unwahrheiten erzielst, sondern
            ein langfristig glückliches Lebensgefühl
          </G.PlanBoxInformation>
        </li>
      </F.TwoColums>
    </F.RightCoachContainer>
  );
};

export default RightCoach;
