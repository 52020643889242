import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import Navbar from "components/Navbar/Navbar";
import StickyNavbar from "components/StickyNavbar/StickyNavbar";
import CoachingWithMe from "components/CoachingWithMe/CoachingWithMe";
import WhyIamYourCoach from "components/WhyIamYourCoach/WhyIamYourCoach";
import StartNutritionChange from "components/StartNutritionChange/StartNutritionChange";
import FreeInitialConsultation from "components/FreeInitialConsultation/FreeInitialConsultation";
import FooterNew from "components/FooterNew/FooterNew";
import MealPlanTools from "components/MealPlanTools/MealPlanTools";
import CoachingGlance from "components/CoachingGlance/CoachingGlance";
import CoachingAccordian from "components/CoachingAccordian/CoachingAccordian";
import SectionWrapper from "components/General/sectionWrapper/SectionWrapper";
import TestimonialSlider from "components/TestimonialSlider/TestimonialSlider";

// Redux
import * as CoachActions from "redux/coach/coach.actions";

// Styles
import * as G from "styles/General.Styles";
import * as C from "./myCoaching.styles";
import FeaturesCards from "components/FeaturesCards/FeaturesCards";
import CoachingProFeatures from "components/CoachingProFeatures/CoachingProFeatures";
import InitialConsultationBox from "components/InitialConsultationBox/InitialConsultationBox";
import FreeInitialConsultationWithSurvey from "components/FreeInitialConsultationWithSurvey/FreeInitialConsultationWithSurvey";
import HelpWithSurvey from "components/HelpWithSurvey/HelpWithSurvey";
import ReimburseHealthInsurance from "components/ReimburseHealthInsurance/ReimburseHealthInsurance";
import LeadSurveyModal from "modals/LeadSurveyModal/LeadSurveyModal";
import { IS_LOCAL } from "configs/utils";

// test coaches :
// 'https://jeff-fci99843.foodiary.app/'
// 'https://ronja-fci49818.foodiary.app'
// 'https://sebastian-fci1.foodiary.app'

const MyCoaching = (props) => {
  const dispatch = useDispatch();
  const coach = useSelector((state) => state.coach.coach);
  const [openLeadModal, setOpenLeadModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    const coach_website_url = IS_LOCAL
      ? 'https://ronja-fci49818.foodiary.app'
      : `${window.location.protocol}//${window.location.host}`

    dispatch(
      CoachActions.trainerIdRequest({
        formData: { coach_website_url },
        callback: (res) => {
          dispatch(
            CoachActions.coachDataRequest({
              formData: { trainerID: res?.trainerID },
              callback: () => null,
            })
          );
        },
      })
    );
  }, [ dispatch ]);

  return (
    <>

      <StickyNavbar openSurveyModal={() => setOpenLeadModal(!openLeadModal)} />

      <div class="overview-wrapper">
        <G.Container>
          <CoachingWithMe openSurveyModal={() => setOpenLeadModal(!openLeadModal)} />
        </G.Container>
      </div>

      <G.Container>

        <SectionWrapper margin="5rem 0">
          <FeaturesCards />
        </SectionWrapper>

        <SectionWrapper margin="25rem 0" mobileMargin="5rem 0">
          <WhyIamYourCoach openSurveyModal={() => setOpenLeadModal(!openLeadModal)} />
        </SectionWrapper>

      </G.Container>

      <SectionWrapper margin="25rem 0" mobileMargin="5rem 0">
          <HelpWithSurvey />
      </SectionWrapper>

      <G.Container>

        <SectionWrapper margin="25rem 0" mobileMargin="5rem 0">
          <CoachingAccordian />
        </SectionWrapper>

        <SectionWrapper margin="25rem 0" mobileMargin="5rem 0">
          <ReimburseHealthInsurance onLeadFormModal={() => setOpenLeadModal(true)} />
        </SectionWrapper>

        <SectionWrapper margin="5rem 0" mobileMargin="5rem 0">
          <TestimonialSlider />
        </SectionWrapper>

      </G.Container>

      <SectionWrapper>
        <InitialConsultationBox
          openLeadFormModal={() => setOpenLeadModal(true)}
        />
      </SectionWrapper>

      <FooterNew />

      {coach && (
        <LeadSurveyModal
          isOpen={openLeadModal}
          onClose={() => setOpenLeadModal(false)}
          coachProfile={coach}
        />
      )}
    </>
  );
};

export default MyCoaching;
