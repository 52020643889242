import React from "react";
import { useSelector } from "react-redux";
import { getS3ImageUrl } from "configs/utils";
import * as F from "./coachingWithMe.style";
import Coach from "assets/images/placeholder.jpg";

const defaultDescription = 'Von Beginn an wirst du von mir durch die Ernährungsumstellung geführt. So starten wir in einem persönlichen Gespräch damit, deinen eigenen Ernährungsplan zu erstellen und die Ernährungsplaner-App einzurichten. Im Anschluss unterstütze ich dich während deiner Ernährungsumstellung und stehe dauerhaft als Ansprechpartner an deiner Seite.'

const CoachingWithMe = ({ openSurveyModal }) => {
  const coach = useSelector((state) => state.coach.coach)

  const imgUrl = coach?.cw_lp_picture_1
    ? getS3ImageUrl(coach?.cw_lp_picture_1)
    : Coach;
  
  const coachDescription = coach?.cw_lp_textfield_1 || defaultDescription
  const prename = coach?.prename || "Coach name"

  return (
    <F.CoachOverviewContainer>
      <div className="overview-content">
        <div className="overview-headline">
          <h1>Foodiary Coaching</h1>
          <h1>mit {prename}</h1>
        </div>
        <div className="overview-description-small">
          <p>Als Ernährungsberater und Coach bei Foodiary unterstütze ich dich bei der Planung und Umsetzung deiner Ernährungsumstellung.</p>
        </div>
        <div className="overview-description-main">
          <p>{coachDescription}</p>
        </div>
        <div className="overview-cta">
          <button onClick={openSurveyModal} className="btn-dark">Erstgespräch vereinbaren</button>
        </div>
      </div>
      <div className="overview-image">
        <div className="overview-image-wrapper">
          <img src={imgUrl} alt="" />
        </div>
      </div>
    </F.CoachOverviewContainer>
  )
};

export default CoachingWithMe;
