import React from "react";
import * as F from "./HealthySteps.style";

import StepCard from "components/General/stepCard/StepCard";
// Images
import IconFood from "assets/images/myCoaching/Icon-food.svg";

const HealthySteps = () => {
  return (
    <F.Wrap>
      <F.SectionTitle className="ct-text-block font-bold color-dark text-left text-3xl">
        Der erste Schritt zu einem gesünderen <br /> Ich dauert nur wenige Minuten
      </F.SectionTitle>
      <F.CardsWrap>
        <StepCard
          title="Analyse deiner Daten"
          description="Klicke auf den Button Ernährungsplan erstellen und in wenigen Minuten wird dein aktueller „IST Zustand“ analysiert. Hier gehen wir zudem auf deinen Tagesablauf und deine präferierten Lebensmittel ein."
          icon={IconFood}
        />
        <StepCard
          title="Dein Ernährungsplan"
          description="Auf Basis deines „IST - Zustand“ wir deine Ernährungsplan erstellt und dir direkt vorgestellt. Du erfährst, welche Ernährungsform für dich geeignet ist und wie sich deine Kalorien zusammen setzen."
          icon={IconFood}
        />
        <StepCard
          title="Let's go!"
          description="Nach dem Start erhältst du direkt Zugriff auf die Smarte Ernährungsplaner App. Du kannst die kommenden Tage planen, dich mit den Funktionen vertraut machen & bereits am nächsten Morgen starten."
          icon={IconFood}
        />
      </F.CardsWrap>
    </F.Wrap>
  );
};

export default HealthySteps;
