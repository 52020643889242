import { NavLink } from 'react-router-dom'
import React, { useState, useEffect } from 'react';
import { Divider, Dropdown, Collapse } from 'antd';
import * as Styles from "./StickyNavbar.style";

import { menuItems } from "constants/index";
import Logo from "assets/images/Foodiary-Logo.png";
import ArrowCircle from "assets/images/arrow-circle.svg";
import MenuIcon from "assets/images/menu-icon.svg";
import CloseMenuIcon from "assets/images/cross.svg";
import ExpandIcon from "assets/images/plus-icon.svg";

const getFormattedSubLinks = subLinks => {
  if (!subLinks) return null

  return subLinks.map(subLink => {

    if (subLink.type === 'divider') return {
      key: subLink.link,
      label: <Styles.DropDivider />
    }

    if (subLink.type === 'outline') return ({
      key: subLink.link,
      label: <Styles.DropSubItemOutline>
        <NavLink
        to={subLink.url}
        className="drop-sub-link"
        >
          {subLink.name}
        </NavLink>
      </Styles.DropSubItemOutline>
    })

    return ({
      key: subLink.link,
      label: <Styles.DropSubItem>
        <NavLink
        to={subLink.url}
        className="drop-sub-link"
        >
          {subLink.name}
        </NavLink>
      </Styles.DropSubItem>
    })
  })
}

function StickyNavbar ({ openSurveyModal }) {
  const [ isActiveBg, setIsActiveBg ] = useState(false)
  const [ mobileMenuOpen, setMobileMenuOpen ] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", shouldBeSticky)
    return () => window.removeEventListener("scroll", shouldBeSticky)
  }, [])

  const shouldBeSticky = () => (
    window.scrollY > 50 && !isActiveBg
    ? setIsActiveBg(true)
    : setIsActiveBg(false)
  )

  return <Styles.RelativeContainer>
    <Styles.NavbarContainer isActiveBg={isActiveBg || mobileMenuOpen}>
      <div className="new-navbar-content">
        <div className="new-navbar-logo">
          <NavLink to="/">
            <img src={Logo} alt="" />
          </NavLink>
        </div>

        <div className="new-navbar-actions-desktop">

          <div className="new-navbar-links">
            {menuItems.map(menuItem => {
              if (!menuItem.subItems) return (
                <NavLink activeClassName='is-active' to={menuItem.url}>
                  {menuItem.name}
                </NavLink>
              )

              return (
                <Dropdown
                  arrow={menuItem.subItems !== null}
                  menu={{ items: getFormattedSubLinks(menuItem.subItems) }}
                  placement="bottom"
                  dropdownRender={menu => {
                    return <Styles.DropdownWrapper>
                      {React.cloneElement(menu)}
                      <Divider />
                    </Styles.DropdownWrapper>
                  }}
                >
                  <NavLink activeClassName='is-active' to={menuItem.url}>{menuItem.name}</NavLink>
                </Dropdown>
              )
            })}
          </div>

          <div className="new-navbar-cta">
            <button onClick={openSurveyModal || (() => {})} className="btn-dark">Erstgespräch vereinbaren</button>
            <div className="new-navbar-login">
              <a href="https://my.foodiary.app/">
                <img src={ArrowCircle} alt="" />
              </a>
            </div>
          </div>

        </div>

        <div className="new-navbar-actions-mobile">
          <div onClick={() => setMobileMenuOpen(!mobileMenuOpen)} className="new-navbar-mobile-trigger">
            <a href="#!">
              <img src={mobileMenuOpen ? CloseMenuIcon : MenuIcon} alt="" />
            </a>
          </div>
          <div className="new-navbar-login">
            <a href="https://my.foodiary.app/">
              <img src={ArrowCircle} alt="" />
            </a>
          </div>
        </div>
      </div>
      {mobileMenuOpen ? (
        <div className="mobile-menu-content">
          <div className="mobile-menu-links">
            {menuItems.map(menuItem => {
              if (menuItem.subItems) return (
                <Styles.CollapseWrapper>
                  <Collapse
                    ghost
                    expandIcon={({ isActive }) => (
                      <Styles.ExpandIcon>
                        <img src={isActive ? CloseMenuIcon : ExpandIcon} />
                      </Styles.ExpandIcon>
                    )}
                    expandIconPosition={'end'}
                    items={[
                      { key: menuItem.link, label: menuItem.name, children: <Styles.CollapseSubMenu>
                        {menuItem.subItems.map(subLink => {
                          console.log('sub link : ', subLink)
                          if (subLink.type === 'divider') return
                          return <NavLink activeClassName='is-active' to={subLink.url}>
                            {subLink.name}
                          </NavLink>
                        })}
                      </Styles.CollapseSubMenu> }
                    ]}
                  />
                </Styles.CollapseWrapper>
              )

              return <NavLink activeClassName='is-active' to={menuItem.url}>
                {menuItem.name}
              </NavLink>
            })}
          </div>
          <Divider />
          <button
            onClick={openSurveyModal}
            className="mobile-menu-cta foodiary-green-button">
              Termin vereinbaren
            </button>
        </div>
      ) : ''}
    </Styles.NavbarContainer>
  </Styles.RelativeContainer>
}

export default StickyNavbar
