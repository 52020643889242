import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import * as CoachActions from "redux/coach/coach.actions";
import LinktreeLayout from "./LinktreeLayout";
import { IS_LOCAL } from "configs/utils";

// 'https://sebastian-fci1.foodiary.app'
// 'https://ronja-fci49818.foodiary.app'

function Linktree () {

  const dispatch = useDispatch()
  const [ coachData, setCoachData ] = useState(null)

  useEffect(() => {
    const coach_website_url = IS_LOCAL
      ? 'https://ronja-fci49818.foodiary.app' || 'https://sebastian-fci1.foodiary.app'
      : `${window.location.protocol}//${window.location.host}`

    console.log('coach website : ', coach_website_url, IS_LOCAL)

    dispatch(
      CoachActions.trainerIdRequest({
        formData: { coach_website_url },
        callback: (res) => {
          dispatch(
            CoachActions.coachDataRequest({
              formData: { trainerID: res?.trainerID },
              callback: setCoachData,
            })
          );
        },
      })
    );

  }, [ dispatch ]);

  if (!coachData) return
  return <LinktreeLayout coachData={coachData} />
}

export default Linktree
