import styled from "styled-components";

export const Wrap = styled.div`
  @media (min-width: 1000px) {
    margin-top: -20rem;
  }
`;

export const CardsWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  padding: 10px 0px;

  @media (max-width: 1000px) {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
    gap: 10px;
  }

  @media (max-width: 1000px) {
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 5px 20px #02332a1a;
  border-radius: 20px;
  text-align: center;
  padding: 30px;
  transition: all 0.3s ease-in-out;
  flex: 0 0 auto;

  img {
    width: 70px;
  }

  &:hover {
    transform: translateY(-10px);
  }

  @media (max-width: 1000px) {
    width: 282px;
    padding: 10px;

    img {
      width: 40px;
    }

    &:hover {
      transform: translateY(0px);
    } 
  }

  p {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
`;

export const CardTitle = styled.h6`
  color: #142c1f;

  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

export const CardDescription = styled.p`
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  text-align: center;
  max-width: 100%;
  margin: 10px 0px;
`;
