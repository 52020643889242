import styled from "styled-components"

export const Wrap = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;

  @media (max-width: 991.99px) {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
`

export const TextContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
`

export const Title = styled.h3.attrs({
  className: `${(props) => props.className}`,
})`
  color: #142c1f;
  /* font-size: 2.2rem;
  font-weight: 500; */
  margin-bottom: 50px;
`

export const VisualContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  img {
    width: 100%;
  }
`
