export const menuItems = [
  {
    name: 'Mein Angebot',
    url: '/mein-coaching',
    subItems: null
  },
  {
    name: 'Online-Kurs',
    url: '/online-kurs',
    subItems: null
  },
  {
    name: 'App',
    url: '/app',
    subItems: [
      {
        name: 'App',
        url: '/app',
      },
      { type: 'divider' },
      {
        name: 'App Erstellen',
        url: '/app-erstellen',
        type: 'outline'
      }
    ]
  },
  {
    name: 'Mein Coaching',
    url: '/coaching',
    subItems: [
      {
        name: 'Mein Coaching',
        url: '/coaching',
      },
      {
        name: 'Erstgespräch',
        url: '/erstgespraech',
      },
      {
        name: 'Coaching PRO',
        url: '/coaching-pro',
      }
    ]
  }
]
