import React from "react";
import { useHistory } from "react-router-dom";
import { PopupButton } from "react-calendly";
import ReactPlayer from "react-player";

import Chip from "components/General/formComponents/chip/Chip";
import * as F from "./OwnNutritionCoach.style";

const OwnNutritionCoach = ({ onLeadFormModal }) => {
  const history = useHistory();

  return (
    <F.CoachingWithMeContainer>
      <F.ContentSection>
        <Chip text="PERSÖNLICHES COACHING" margin="0 0 2rem 0" />
        <F.Title className="ct-text-block font-bold color-dark text-left text-4xl">
          Ein eigener Ernährungscoach ist der Schlüssel zum Erfolg!
        </F.Title>
        <F.Description className="ct-text-block color-paragraph text-base text-left">
          Konzentriere dich während deiner Ernährungsumstellung voll und ganz
          auf die Umsetzung und überlasse mir die lästige Arbeit. Du bist dir
          nicht sicher, ob du das Rezept essen darfst, was du machen sollst,
          wenn dein Gewicht stagniert oder ein Geburtstag ansteht? – Kein
          Problem! Ich bin als dein Coach immer für dich da und übernehme gern
          die Aufgabe.
        </F.Description>

        <div className="mt-10">
          <button className="btn-dark" onClick={() => onLeadFormModal()}>Erstgespräch vereinbaren</button>
        </div>

      </F.ContentSection>

      <F.VisualContent>
        <ReactPlayer
          url={"https://foodiary.app/data/foodiary-coach-live-chat.mp4"}
          playsinline
          playing
          muted
          loop
        />
        <div className="white-border" />
      </F.VisualContent>
    </F.CoachingWithMeContainer>
  );
};

export default OwnNutritionCoach;
